import React, { FunctionComponent } from 'react';
import { ImageFieldsFragment } from '../../../../__generated__/graphql-client-types';
import { CART_LINK } from '../../../../constants/links';
import { buildGTMViewCart, buildGTMViewCartItem } from '../../../../helpers/analytics/gtm/gtm-utils.helper';
import { useTrackEvent } from '../../../../hooks/analytics/analytics.hooks';
import { useNavigation } from '../../../../hooks/navigation/navigation.hooks';
import { ClickableElement } from '../../../buttons';
import { Carousel } from '../../../carousel/carousel.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { ProductPrice } from '../../../product-components/product-price/product-price.component';
import { HomeDashboardWidgetWrapper } from '../../home-dashboard-widget-wrapper/home-dashboard-widget-wrapper.component';

type CartItem = {
	manufacturer: string;
	title: string;
	price: number;
	image: ImageFieldsFragment;
	productUrl: string;
	id?: number;
};

const CartItemCard: FunctionComponent<CartItem> = ({ manufacturer, title, price, image, productUrl }) => {
	const navigate = useNavigation();
	const trackEvent = useTrackEvent();

	const handleClick = (url: string) => {
		trackEvent(buildGTMViewCartItem());
		navigate(url);
	};
	return (
		<ClickableElement ariaLabel={`${manufacturer}-${title}`} onClick={() => handleClick(productUrl)} testId={`dash-cart-item`}>
			<div style={{ height: 180, width: 112 }} className="pl2 pb2 pr2 flex flex-column items-center f5">
				<CloudinaryImage publicID={image.id} description={image.description} options={{ height: 90, width: 90 }} />
				<div className="mt1">
					<div className="tc2-title">
						<span className="b">{manufacturer} </span>
						<span className="fw2">{title}</span>
					</div>
					<ProductPrice current={price} />
				</div>
			</div>
		</ClickableElement>
	);
};

export type DashboardCartItemsProps = {
	cartItems: CartItem[];
};

export const DashboardCartItems: FunctionComponent<DashboardCartItemsProps> = ({ cartItems }) => (
	<HomeDashboardWidgetWrapper
		url={CART_LINK}
		heading="In Your Cart"
		linkText="View Cart"
		analyticsEvent={{ data: buildGTMViewCart() }}
		testId="cart-items-widget">
		<Carousel>
			<div className="flex justify-center mh3 mt2 mt0-ns">
				{cartItems.map((item) => (
					<CartItemCard key={item.id} {...item} />
				))}
			</div>
		</Carousel>
	</HomeDashboardWidgetWrapper>
);
